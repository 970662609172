import clsx from "clsx"
import React, { useState } from "react"
import OnVisible from "react-on-visible"
import styled from "styled-components"

interface BenefitProps {
  text: string
  icon: string
}
const Benefit: React.FC<BenefitProps> = ({ text, icon }) => {
  const [visible, setVisible] = useState(false)
  return (
    <OnVisible
      onChange={setVisible}
      className={clsx(
        "bg-white rounded-md p-4 flex items-center transition-opacity transform-gpu duration-500",
        visible ? "" : "opacity-0"
      )}
    >
      <img src={icon} alt={text} className="pr-4" />
      <div className="flex-1 text-primary text-sm leading-5">{text}</div>
    </OnVisible>
  )
}

const PartneringSection: React.FC = () => (
  <section className="bg-gray-background overflow-hidden">
    <Content className="mx-auto" id="why-us">
      <h1 className="text-4xl font-bold text-primary text-center pb-24">
        The Benefits of Partnering with Brima Med
      </h1>
      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 md:px-4 lg:px-32 xl:px-0 md:gap-x-16 gap-y-12">
        <Benefit
          text="Visibility of PMB Accounts"
          icon="/img/benefit-icons/1.png"
        />
        <Benefit
          text="No hidden costs, just the agreed percentage of billings"
          icon="/img/benefit-icons/2.png"
        />
        <Benefit
          text="On-the-go billing and real-time view"
          icon="/img/benefit-icons/3.png"
        />
        <Benefit text="Free debt collection" icon="/img/benefit-icons/4.png" />
        <Benefit
          text="Staff trained in medical billing "
          icon="/img/benefit-icons/5.png"
        />
        <Benefit
          text="Free monthly accounts report "
          icon="/img/benefit-icons/6.png"
        />
      </div>
    </Content>
  </section>
)

const Content = styled.div`
  max-width: 1092px;
  padding: 150px 24px 120px;
  @media screen and (min-width: 768px) {
    padding: 150px 24px 200px;
  }
  @media screen and (min-width: 1024px) {
    padding: 150px 24px 300px;
  }
  @media screen and (min-width: 1280px) {
    padding: 150px 24px 470px;
  }
`

export default PartneringSection
