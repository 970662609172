import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import styled from "styled-components"
import Button from "../Button"
import GatsbyImage from "gatsby-image"

const Background = styled.svg`
  width: 100%;
  height: 705px;
  max-height: 100vh;
  position: absolute;
  z-index: -1;
  top: 0;
`

const Wrapper = styled.section`
  padding-top: 200px;
  min-height: 100vh;

  @media screen and (min-width: 768px) {
    padding-top: 100px;
  }
`

const query = graphql`
  query {
    isolated: file(relativePath: { eq: "doctor-smiling-isolated.png" }) {
      childImageSharp {
        fixed(width: 640, quality: 100) {
          ...GatsbyImageSharpFixed_withWebp_tracedSVG
        }
      }
    }

    masked: file(relativePath: { eq: "doctor-smiling.jpg" }) {
      childImageSharp {
        fixed(width: 640, quality: 100) {
          ...GatsbyImageSharpFixed_withWebp_tracedSVG
        }
      }
    }
  }
`

const HomeSection: React.FC = () => {
  const keySplines = "0.52 0.1 0.52 0.83"
  const duration = "5s"

  const data = useStaticQuery(query)

  return (
    <Wrapper className="overflow-hidden">
      <Background
        width="1400"
        height="704.618"
        viewBox="0 0 1400 704.618"
        preserveAspectRatio="none"
      >
        <defs>
          <linearGradient
            id="linear-gradient"
            x1="0.5"
            x2="0.5"
            y2="1"
            gradientUnits="objectBoundingBox"
          >
            <stop offset="0" stopColor="#f5fafa" />
            <stop offset="1" stopColor="#e7f3f4" />
          </linearGradient>
          <clipPath id="clip-path">
            <path
              id="Mask"
              d="M0,667.961s488.609,106.758,848.8-44.1S1400,283,1400,283V0H0Z"
              transform="translate(0.184 0.453)"
              fill="url(#linear-gradient)"
            />
          </clipPath>
          <linearGradient
            id="linear-gradient-3"
            x1="0.383"
            y1="-0.021"
            x2="0.121"
            y2="0.992"
            gradientUnits="objectBoundingBox"
          >
            <stop offset="0" stopColor="#e1fffb" />
            <stop offset="1" stopColor="#afdef2" />
          </linearGradient>
        </defs>
        <g
          id="Mask_Mask_Copy_Mask"
          data-name="Mask + Mask Copy Mask"
          transform="translate(-0.184 -0.453)"
        >
          <path
            id="Mask-2"
            data-name="Mask"
            d="M0,667.961s488.609,106.758,848.8-44.1S1400,283,1400,283V0H0Z"
            transform="translate(0.184 0.453)"
            fill="url(#linear-gradient)"
          />
          <g
            id="Mask_Mask_Copy_Mask-2"
            data-name="Mask + Mask Copy Mask"
            clipPath="url(#clip-path)"
          >
            <path
              id="Mask-3"
              data-name="Mask"
              d="M224.744,633.116C88.545,598.342-9.994,441.076.809,303.987,11.55,166.361,112.177,40.971,222.459,5.669c109.763-34.46,208.335,91.071,223.935,298.318C462.049,510.7,361.461,668.1,224.744,633.116Z"
              transform="matrix(0.259, -0.966, 0.966, 0.259, 224.894, 55.304)"
              fill="#fff"
            />
            <path
              id="Mask_Copy"
              data-name="Mask Copy"
              d="M224.744,469.379C88.545,443.6-9.994,327,.809,225.37,11.55,123.337,112.177,30.375,222.459,4.2,332.222-21.345,430.794,71.721,446.393,225.37,462.049,378.621,361.461,495.313,224.744,469.379Z"
              transform="matrix(-0.883, -0.469, 0.469, -0.883, 134.75, 1028.479)"
              fill="#cddded"
            />
            <path
              id="Mask-4"
              data-name="Mask"
              d="M601.834,303.987C622.941,510.7,487.327,668.1,303,633.116,119.377,598.342-13.474,441.076,1.091,303.987,15.572,166.361,151.239,40.971,299.922,5.669,447.906-28.792,580.8,96.739,601.834,303.987"
              transform="translate(786.184 192.453)"
              fill="#77aeb4"
              opacity="0.378"
            />
            <path
              id="Mask-5"
              data-name="Mask"
              d="M0,667.961s488.609,106.758,848.8-44.1S1400,283,1400,283V0H0Z"
              transform="translate(0.184 0.453)"
              fill="url(#linear-gradient-3)"
            />
          </g>
        </g>
      </Background>

      <Content className="flex mx-auto flex-col items-center lg:flex-row lg:items-start">
        <div className="flex-1 mt-8 lg:mt-36">
          <SiteTitle className="text-4xl md:text-5xl text-primary font-medium">
            You Take Care of Your Patients and We’ll Take Care of the Payments.
          </SiteTitle>
          <p className="text-primary pt-4 pr-24 leading-8 mb-9">
            Rely on Brima Med to capture data, submit claims, and as a result,
            increase your practice turnover.
          </p>
          <Button href="#consultation" className="mb-6">
            Start a 2 week free trial now
          </Button>
        </div>

        <div className="relative z-10 flex justify-center -mr-20">
          <svg width="0" height="0" xmlns="http://www.w3.org/2000/svg">
            <defs>
              <clipPath id="myClip" clipPathUnits="objectBoundingBox">
                <path
                  fill="#FF0066"
                  d="M61.9,-24.1C69.3,2.6,56.8,31.8,33.5,49.6C10.2,67.4,-24,73.7,-45.4,58.9C-66.8,44.2,-75.5,8.4,-65.8,-21.5C-56.1,-51.4,-28.1,-75.5,-0.4,-75.3C27.3,-75.2,54.5,-50.9,61.9,-24.1Z"
                  transform="scale(0.005) translate(100, 100)"
                  stroke="#000000"
                  strokeWidth="4"
                >
                  <animate
                    attributeType="XML"
                    attributeName="d"
                    from="M61.9,-24.1C69.3,2.6,56.8,31.8,33.5,49.6C10.2,67.4,-24,73.7,-45.4,58.9C-66.8,44.2,-75.5,8.4,-65.8,-21.5C-56.1,-51.4,-28.1,-75.5,-0.4,-75.3C27.3,-75.2,54.5,-50.9,61.9,-24.1Z"
                    to="M69.9,-23.1C79.3,6.2,67.8,41.9,43.3,59.8C18.8,77.7,-18.8,77.7,-43.7,59.7C-68.6,41.7,-80.8,5.8,-71.4,-23.5C-62,-52.9,-31,-75.5,-0.4,-75.4C30.2,-75.3,60.5,-52.4,69.9,-23.1Z"
                    dur="2s"
                    begin="0s; anim4.end"
                    id="anim1"
                    keySplines={keySplines}
                    calcMode="spline"
                  />
                  <animate
                    attributeType="XML"
                    attributeName="d"
                    from="M69.9,-23.1C79.3,6.2,67.8,41.9,43.3,59.8C18.8,77.7,-18.8,77.7,-43.7,59.7C-68.6,41.7,-80.8,5.8,-71.4,-23.5C-62,-52.9,-31,-75.5,-0.4,-75.4C30.2,-75.3,60.5,-52.4,69.9,-23.1Z"
                    to="M60.7,-19.2C68.5,4.6,57.9,34.8,35.3,52.1C12.6,69.5,-22.1,73.8,-44.1,58.4C-66.1,43,-75.4,7.7,-65.9,-18.2C-56.5,-44.1,-28.2,-60.7,-0.9,-60.4C26.4,-60.1,52.8,-42.9,60.7,-19.2Z"
                    dur="2s"
                    begin="anim1.end"
                    id="anim2"
                    keySplines={keySplines}
                    calcMode="spline"
                  />
                  <animate
                    attributeType="XML"
                    attributeName="d"
                    from="M60.7,-19.2C68.5,4.6,57.9,34.8,35.3,52.1C12.6,69.5,-22.1,73.8,-44.1,58.4C-66.1,43,-75.4,7.7,-65.9,-18.2C-56.5,-44.1,-28.2,-60.7,-0.9,-60.4C26.4,-60.1,52.8,-42.9,60.7,-19.2Z"
                    to="M59.8,-18.7C68.2,6.6,59.4,38.2,38.6,53.4C17.7,68.6,-15.2,67.5,-38.9,50.9C-62.6,34.3,-77.1,2.3,-69,-22.4C-61,-47.1,-30.5,-64.4,-2.4,-63.7C25.7,-62.9,51.3,-43.9,59.8,-18.7Z"
                    dur="2s"
                    begin="anim2.end"
                    id="anim3"
                    keySplines={keySplines}
                    calcMode="spline"
                  />
                  <animate
                    attributeType="XML"
                    attributeName="d"
                    from="M59.8,-18.7C68.2,6.6,59.4,38.2,38.6,53.4C17.7,68.6,-15.2,67.5,-38.9,50.9C-62.6,34.3,-77.1,2.3,-69,-22.4C-61,-47.1,-30.5,-64.4,-2.4,-63.7C25.7,-62.9,51.3,-43.9,59.8,-18.7Z"
                    to="M61.9,-24.1C69.3,2.6,56.8,31.8,33.5,49.6C10.2,67.4,-24,73.7,-45.4,58.9C-66.8,44.2,-75.5,8.4,-65.8,-21.5C-56.1,-51.4,-28.1,-75.5,-0.4,-75.3C27.3,-75.2,54.5,-50.9,61.9,-24.1Z"
                    dur="2s"
                    begin="anim3.end"
                    id="anim4"
                    keySplines={keySplines}
                    calcMode="spline"
                  />
                </path>
              </clipPath>
            </defs>
          </svg>

          <GatsbyImage
            style={{ position: "relative", alignSelf: "flex-start" }}
            fixed={data.masked.childImageSharp.fixed}
            imgStyle={{ clipPath: "url(#myClip)"}}
          />
          <GatsbyImage
            style={{ position: "absolute", top: 0, maxWidth: "100%" }}
            fixed={data.isolated.childImageSharp.fixed}
          />
        </div>
      </Content>
    </Wrapper>
  )
}

const Content = styled.div`
  max-width: 1248px;
  padding-left: 38px;
  padding-right: 38px;
`

const SiteTitle = styled.h1`
  line-height: 57px;
  @media screen and (max-width: 640px) {
    line-height: 38px;
  }
`

export default HomeSection
