import React from "react"

interface Props {
  element: React.ReactElement
}
const wrapRootElement: React.FC<Props> = ({ element }) => {
  return element 
}

export default wrapRootElement
