import clsx from "clsx"
import React, { HTMLProps } from "react"

import AnchorLink from "react-anchor-link-smooth-scroll"

const Button: React.FC<React.AnchorHTMLAttributes<HTMLAnchorElement>> = ({
  children,
  className,
  href,
}) => (
  <AnchorLink
  offset={200}
    className={clsx(
      "inline-block bg-blue hover:bg-blue-700 rounded-md text-white py-5 px-8 font-semibold focus:outline-none shadow-button cursor-pointer",
      className
    )}
    href={href || ""}
  >
    {children}
  </AnchorLink>
)

export default Button
