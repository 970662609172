import React from "react"

import { Layout } from "./components"

interface Props {
  element: React.ReactElement
}
const wrapPageElement: React.FC<Props> = ({ element, ...props }) => {
  return <Layout {...props}>{element}</Layout>
}
export default wrapPageElement
