import React from "react"
import styled from "styled-components"

const Footer: React.FC = () => {
  const copyRightYear = new Date().getFullYear()
  return (
    <section className="bg-blue-light">
      <Content className="mx-auto flex justify-between items-center">
        <div>
          <span className="font-bold text-primary">
            BRIMA <span className="font-normal text-xs">Medical Bureau</span>
          </span>
        </div>
        <div className="flex">
          <a className="text-primary text-xs text-right mx-4" href="/privacy">Privacy</a>
        <div className="text-primary text-xs text-right">© 2003-{copyRightYear} BRIMA Medical Bureau, Inc.</div>
        </div>
      </Content>
    </section>
  )
}

const Content = styled.div`
  max-width: 1248px;
  padding-left: 24px;
  padding-right: 24px;
  height: 77px;
`

export default Footer
