import React, { useState } from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"
import Button from "../Button"
import OnVisible from "react-on-visible"

const Content = styled.div`
  max-width: 952px;
  padding: 0 24px;
`

const TextContent = styled(OnVisible)`
  max-width: 464px;
`

const query = graphql`
  query {
    file(relativePath: { eq: "hand-image.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fixed(width: 317, height: 608, quality: 100) {
          ...GatsbyImageSharpFixed_withWebp_tracedSVG
        }
      }
    }
  }
`

const AppSection: React.FC = () => {
  const data = useStaticQuery(query)

  const [imageVisible, setImageVisible] = useState(false)
  const [textVisible, setTextVisible] = useState(false)
  return (
    <section className="overflow-visible">
      <Content className="flex flex-col md:flex-row justify-between items-center mx-auto overflow-visible">
        <TextContent
          onChange={setTextVisible}
          className={`my-12 transform-gpu transition-all duration-300 origin-left ${
            textVisible ? "" : "-translate-x-32 scale-50 opacity-50"
          }`}
        >
          <h2 className="text-primary text-4xl pb-6 font-bold">
            Introducing Submitt.app
          </h2>
          <p className="text-primary leading-7 pb-9">
            Manage doctors, assistants and patients all in one place. And get
            rid of all the paperwork with our cloud-based billing app. Now you
            can get claims to us faster and easier than ever before.
          </p>
          <div className="flex justify-center md:justify-start pb-14">
            <a className="inline-block mr-14" href="" target="_blank">
              <img src="/img/apple-app-store-logo.png" alt="Apple App Store" />
            </a>
            <a className="inline-block" href="" target="_blank">
              <img src="/img/google-play-logo.png" alt="Google Play" />
            </a>
          </div>
          <a
            className="flex justify-center md:inline-block bg-blue hover:bg-blue-700 rounded-md text-white py-3 px-10 md:px-16 font-semibold shadow-button cursor-pointer focus:outline-none"
            target="_blank"
            href="https://www.youtube.com/watch?v=giyehxYzxtc"
          >
            <img
              alt="YouTube"
              src="/img/youtube-icon.png"
              className="inline-block mr-5 align-middle"
            />
            <span className="inline-block align-middle">See how it works</span>
          </a>
        </TextContent>
        <OnVisible
          onChange={setImageVisible}
          className={`transform-gpu transition-all duration-300 origin-right ${
            imageVisible ? "" : "translate-x-32 scale-50 opacity-50"
          }`}
        >
          <Img
            className="align-bottom flex-shrink-0 md:-mt-9"
            fixed={data.file.childImageSharp.fixed}
          />
        </OnVisible>
      </Content>
    </section>
  )
}

export default AppSection
