import React, { HTMLAttributes } from "react"

import styled from "styled-components"

const TextArea = styled.textarea`
  height: 155px;
`

interface Props extends HTMLAttributes<HTMLInputElement | HTMLTextAreaElement> {
  label: string
  name: string
  value: string
  multiline?: boolean
  error?: string
  touched?: boolean
}

const LabelledTextInput: React.FC<Props> = ({
  name,
  value,
  label,
  multiline,
  error,
  touched,
  ...props
}) => {
  const inputClasses = `w-full p-3.5 rounded-md border-gray-border border-2 text-sm placeholder-gray-border placeholder-opacity-100 placeholder-gray-placeholder focus:border-blue text-secondary outline-none resize-none`
  return (
    <div className="w-full mb-9">
      <label htmlFor={name} className="block text-primary pb-2.5 leading-5">
        {label}
      </label>
      {multiline ? (
        <TextArea
          {...props}
          id={name}
          name={name}
          value={value}
          className={inputClasses}
          placeholder={label}
        />
      ) : (
        <input
          {...props}
          id={name}
          name={name}
          value={value}
          className={inputClasses}
          placeholder={label}
        />
      )}
      {error && touched ? (
        <div>
          <p className="text-red-700">{error}</p>
        </div>
      ) : null}
    </div>
  )
}

export default LabelledTextInput
