import * as Yup from "yup"

import React, { useState } from "react"
import { graphql, useStaticQuery } from "gatsby"

// @ts-ignore
import Background from "./img/background.inline.svg"
import GatsbyImage from "gatsby-image"
import LabelledTextInput from "../LabelledTextInput"
import OnVisible from "react-on-visible"
import clsx from "clsx"
import styled from "styled-components"
import { useFormik } from "formik"

interface ContactDetailProps {
  text: string
  icon: string
  highlighted?: boolean
  href: string
  isemailbtn?: boolean
}

const ContactDetail: React.FC<ContactDetailProps> = ({
  text,
  icon,
  highlighted,
  href,
  isemailbtn,
}) => (
  <a
    href={href}
    target="_blank"
    className={`focus:outline-none block p-4 mb-5 ${
      highlighted ? "rounded-md bg-blue bg-opacity-60" : null
    } ${ isemailbtn ? "email-button" : null}`}
  >
    <img
      src={icon}
      alt={text}
      className="align-middle inline-block pr-2 sm:pr-6"
    />
    <span
      className={`${
        highlighted ? "text-white" : "text-primary"
      } font-medium text-sm sm:text-lg align-middle`}
    >
      {text}
    </span>
  </a>
)

const query = graphql`
  query {
    file(relativePath: { eq: "doctor-2.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 536, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }
`

const CallUsSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  email: Yup.string().email("Invalid email").required("Required"),
  message: Yup.string()
    .min(2, "Too Short!")
    .max(300, "Too Long!")
    .required("Required"),
  jar_o_jam: Yup.string(),
})

const CallUsSection: React.FC = () => {
  const data = useStaticQuery(query)

  const [formLoading, setFormLoading] = useState(false)
  const [formSubmitted, setFormSubmitted] = useState(false)
  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      message: "",
      jar_o_jam: "",
    },
    validationSchema: CallUsSchema,
    onSubmit: async (values, formikHelpers) => {
      setFormSubmitted(false)
      setFormLoading(true)
      const url = `${process.env.CONTACT_US_SERVICE_URL || ""}/enquiries`
      const apiKey = process.env.CONTACT_US_API_KEY || ""
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "omit",
        headers: {
          "Content-Type": "application/json",
          "X-API-Key": apiKey,
        },
        referrerPolicy: "no-referrer",
        body: JSON.stringify(values),
      })
      console.log(response)
      formikHelpers.resetForm()
      setFormLoading(false)
      setFormSubmitted(true)
    },
  })

  const [formVisible, setFormVisible] = useState(false)
  const [infoVisible, setInfoVisible] = useState(false)
  const [imageVisible, setImageVisible] = useState(false)

  return (
    <section className="relative bg-gray-background overflow-hidden">
      <Background
        className="w-full h-full absolute z-0 bottom-0"
        preserveAspectRatio="none"
        width="1400"
        height="1509.072"
      />
      <AboutContainer
        className="lg:flex lg:justify-between mx-auto relative pt-12"
        id="about-us"
      >
        <OnVisible
          onChange={setImageVisible}
          className={`flex-1 duration-300 transform-gpu transition-all ${
            imageVisible ? "" : "-translate-x-32 opacity-30"
          }`}
        >
          <AboutImage
            className="-mt-10 md:mx-auto lg:mx-0"
            // @ts-ignore
            fluid={data.file.childImageSharp.fluid}
          />
        </OnVisible>
        <AboutInfoContainer>
          <h1 className="text-primary font-medium text-4xl pb-9 pt-4">
            About Brima Med?
          </h1>
          <p className="text-primary leading-7">
            Brima Medical Bureau was started by Lee-Ann Britz in 1996 and is
            still currently run by Lee-Ann and her business partner Joaquim
            Martins. Today, Brima Med is one of the top Medical bureaus in the
            country, that prides itself on providing only the best services to
            Medical Practitioners by having only qualified and experienced
            staff, experienced in a variety of billing specialities from
            Neurosurgery, Cardiology, Orthopaedics all the way to General
            Practitioners.{" "}
          </p>
        </AboutInfoContainer>
      </AboutContainer>
      <ContactContainer className="lg:flex lg:justify-between mx-auto pb-36 relative">
        <InfoContainer
          onChange={setInfoVisible}
          className={clsx(
            "flex flex-col pb-10 lg:pb-0 transition-all transform-gpu origin-left duration-300",
            infoVisible ? "" : "-translate-x-32 opacity-30"
          )}
        >
          <div className="flex-1" id="contact-us">
            <h1 className="text-primary font-medium text-4xl pb-9 pt-4">
              Call Us Today
            </h1>
            <p className="text-primary pb-20">
              Should you be interested in our services, contact one of our
              consultants
            </p>
            <div>
              <ContactDetail
                text="+27 79 895 1884"
                icon="/img/contact-icons/phone.png"
                href="tel:+27798951884"
              />
              <ContactDetail
                text="joe@brimamed.co.za"
                icon="img/contact-icons/mail.png"
                highlighted
                href="mailto:joe@brimamed.co.za"
                isemailbtn
              />
              <ContactDetail
                text="Zuid-Afrikaans Hospital, Suite 315"
                href="http://maps.google.com/?q=Zuid-Afrikaans Hospital"
                icon="img/contact-icons/pin.png"
              />
              <ContactDetail
                text="Mooikloof Office Park East, Building 7"
                href="http://maps.google.com/?q=Mooikloof Office Park East"
                icon="img/contact-icons/pin.png"
              />
              <ContactDetail
                text="Cape Town - Coming Soon"
                href="http://maps.google.com/?q=Cape Town"
                icon="img/contact-icons/pin-grey.png"
              />
            </div>
          </div>
          {/* <div className="flex">
            <a
              href="https://facebook.com"
              target="_blank"
              className="mr-7 ml-3"
            >
              <img src="/img/facebook.png" alt="Facebook" />
            </a>
            <a href="https://twitter.com/BrimaMedicalBur" target="_blank">
              <img src="/img/twitter.png" alt="Twitter" />
            </a>
          </div> */}
        </InfoContainer>
        <FormBox
          onChange={setFormVisible}
          className={clsx(
            "bg-white rounded-md px-10 py-11 duration-300 transform-gpu origin-right transition-all m-auto lg:m-0",
            formVisible ? "" : "translate-x-32 opacity-30"
          )}
        >
          <form
            onSubmit={formik.handleSubmit}
            className="flex flex-col items-end"
          >
            {formSubmitted && (
              <p className="text-blue-500 mb-5">
                Thank you! A consultant will be in touch with you soon.
              </p>
            )}
            <LabelledTextInput
              label="Name"
              name="name"
              onChange={formik.handleChange}
              value={formik.values.name}
              error={formik.errors.name}
              touched={formik.touched.name}
            />
            <LabelledTextInput
              label="Mail"
              name="email"
              onChange={formik.handleChange}
              value={formik.values.email}
              error={formik.errors.email}
              touched={formik.touched.email}
            />
            <LabelledTextInput
              label="Message"
              name="message"
              onChange={formik.handleChange}
              value={formik.values.message}
              error={formik.errors.message}
              touched={formik.touched.message}
              multiline
            />
            <input
              id="jar_o_jam"
              name="jar_o_jam"
              onChange={formik.handleChange}
              value={formik.values.jar_o_jam}
              type="hidden"
            />
            <button
              type="submit"
              className="bg-blue hover:bg-blue-700 rounded-md text-white py-3 px-16 font-semibold shadow-button cursor-pointer focus:outline-none m-auto lg:m-0"
            >
              {formLoading ? "Sending..." : "Send Message"}
            </button>
          </form>
        </FormBox>
      </ContactContainer>
    </section>
  )
}

const InfoContainer = styled(OnVisible)`
  max-width: 410px;
  .email-button {
    max-width: 15rem;
  }
  @media screen and (min-width: 768px) {
    .email-button {
      max-width: 20rem;
    }
  }
`

const ContactContainer = styled.div`
  max-width: 1128px;
  padding-left: 24px;
  padding-right: 24px;
`

const AboutContainer = styled.div`
  max-width: 1128px;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 270px;
`

const AboutInfoContainer = styled.div`
  width: 100%;
  @media screen and (min-width: 1024px) {
    max-width: 483px;
  }
`

const AboutImage = styled(GatsbyImage)`
  max-width: 430px; 
  @media screen and (min-width: 1280px) {
    max-width: 536px; 
  }
`

const FormBox = styled(OnVisible)`
  width: 100%;

  @media screen and (min-width: 768px) {
    max-width: 483px;
  }
`

export default CallUsSection
