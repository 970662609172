import * as Yup from "yup"

import OnVisible, { setDefaultProps } from "react-on-visible"
import React, { useCallback, useState } from "react"

// @ts-ignore
import Background from "./img/background.inline.svg"
import Button from "../Button"
import styled from "styled-components"
import { useFormik } from "formik"

interface Props {
  title: string
  cost: string
  services: string[]
  collectedOnly?: boolean
  highlighted?: boolean
  termsStart: string
  terms: string
}
const Option: React.FC<Props> = ({
  title,
  cost,
  collectedOnly,
  services,
  highlighted,
  termsStart,
  terms,
}) => {
  const [visible, setVisible] = useState(false)
  return (
    <OnVisible
      onChange={setVisible}
      className={`${
        highlighted ? "pt-4" : "md:pt-12"
      } relative transform-gpu transition-all origin-bottom duration-300 ${
        visible
          ? "scale-100 opacity-100 translate-y-0"
          : "scale-50 origin-bottom opacity-10 translate-y-36 transition-all transform-gpu"
      }`}
    >
      <div
        className={`flex w-full h-full flex-col ${
          highlighted ? "bg-blue" : "bg-white border-2"
        } border-blue-lightest rounded-lg px-3.5 pb-3.5 max-w-sm mx-auto relative`}
      >
        {highlighted ? (
          <h4 className="px-7 py-2 left-7 bg-green rounded-full text-white font-semibold absolute -top-4 text-sm mx-auto">
            TOP CHOICE!
          </h4>
        ) : null}
        <div className={`${highlighted ? "pt-14" : "pt-7"} px-3.5 flex-1`}>
          <h5
            className={`${
              highlighted ? "text-white" : "text-primary"
            } font-medium text-lg pb-5`}
          >
            {title}
          </h5>
          <p
            className={`text-xs ${
              highlighted ? "text-white" : "text-primary"
            } pb-1`}
          >
            COST:
          </p>
          <h3
            className={`${
              highlighted ? "text-white" : "text-primary"
            } font-bold text-3xl pb-6`}
          >
            {cost}{" "}
            {collectedOnly ? (
              <span className="text-xs font-normal align-top">
                on payments collected{" "}
                <strong className="font-semibold">only</strong>
              </span>
            ) : null}
          </h3>
          <p
            className={`${
              highlighted ? "text-white" : "text-secondary"
            } text-xs pb-4`}
          >
            SERVICES INCLUDE:
          </p>
          <ServiceList highlighted={highlighted}>
            {services.map(service => (
              <li key={service}>{service}</li>
            ))}
          </ServiceList>
        </div>
        <div
          className={`text-sm lg:text-xxs ${
            highlighted ? "text-white" : "text-primary"
          } p-3.5 bg-blue-lighter rounded-md bg-opacity-25`}
        >
          <strong>{termsStart}</strong>
          {terms}
        </div>
      </div>
    </OnVisible>
  )
}

const ConsultationSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  contactNumber: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  jar_o_jam: Yup.string(),
})

const OptionsSection: React.FC = () => {
  const [formLoading, setFormLoading] = useState(false)
  const [formSubmitted, setFormSubmitted] = useState(false)
  const formik = useFormik({
    initialValues: {
      name: "",
      contactNumber: "",
      time: "morning",
      jar_o_jam: "",
      form_id: "consultation",
    },
    validationSchema: ConsultationSchema,
    onSubmit: async (values, formikHelpers) => {
      setFormSubmitted(false)
      setFormLoading(true)
      const url = `${process.env.CONTACT_US_SERVICE_URL || ""}/enquiries`
      const apiKey = process.env.CONTACT_US_API_KEY || ""
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "omit",
        headers: {
          "Content-Type": "application/json",
          "X-API-Key": apiKey,
        },
        referrerPolicy: "no-referrer",
        body: JSON.stringify(values),
      })
      console.log(response)
      formikHelpers.resetForm()
      setFormLoading(false)
      setFormSubmitted(true)
    },
  })

  const handleToggleTime = useCallback(() => {
    let value = "morning"
    if (formik.values["time"] === "morning") {
      value = "afternoon"
    }
    formik.setFieldValue("time", value)
  }, [formik.values])

  return (
    <section className="relative">
      <Background
        className="w-full absolute z-0 bottom-0"
        preserveAspectRatio="none"
      />
      <Content className="mx-auto z-10 relative" id="services">
        <p className="text-center text-secondary text-xs uppercase mb-8">
          Flexibility to Fit Your Practice Needs
        </p>
        <MainText className="text-center text-primary text-3xl mb-12">
          Every practice has unique accounting and claim submission needs,
          that’s why we provide different service options.
        </MainText>

        <div className="grid lg:grid-cols-3 gap-7 xl:gap-11 pb-28">
          <Option
            title="Option 1"
            cost="Payment Plan"
            services={[
              "Submit billings to Medical Aids",
              "Rectify rejections",
              "Follow up on outstanding accounts",
            ]}
            termsStart="*Requires"
            terms=" practice to capture patient information and billing of the accounts."
          />
          <Option
            title="Option 2"
            cost="3%"
            collectedOnly
            services={[
              "Rectify rejections",
              "Follow up on outstanding accounts",
              "Allocate payments",
            ]}
            termsStart="*Requires"
            terms=" practice to capture patient data, bill accounts &amp; submit claim to Medical Aids."
          />
          <Option
            title="Option 3"
            cost="4%"
            collectedOnly
            services={[
              "Capture all patient data",
              "Bills and necessary codes",
              "Submit all claims to Medical Aids with real-time response",
              "Rectify rejections",
              "Follow up on accounts that member will be liable for",
            ]}
            highlighted
            termsStart="*Brima Med"
            terms=" assumes full control of the doctors accounts"
          />
        </div>

        <Consultation className="mx-auto">
          <h3 className="text-primary text-3xl text-center">
            Set Up a Consultation
          </h3>
          <p className="text-primary text-center pb-8">
            Leave your details and we’ll be in touch to chat about the best
            option for your practice
          </p>
          <form onSubmit={formik.handleSubmit}>
            <div className="bg-white bg-opacity-40 rounded-xl pt-5 pb-8 px-6 lg:grid grid-cols-4 gap-4">
              <div>
                <label className="text-xs text-primary block pb-2">Name</label>
                <input
                  className="rounded-md bg-white w-full p-4 text-xs mb-4 text-primary focus:outline-none"
                  placeholder="Name"
                  id="name"
                  name="name"
                  onChange={formik.handleChange}
                  value={formik.values.name}
                />
              </div>
              <div>
                <label className="text-xs text-primary block pb-2">
                  Contact Number
                </label>
                <input
                  className="rounded-md bg-white w-full p-4 text-xs mb-4 text-primary focus:outline-none"
                  placeholder="Contact Number"
                  id="contactNumber"
                  name="contactNumber"
                  onChange={formik.handleChange}
                  value={formik.values.contactNumber}
                />
                <input
                  id="jar_o_jam"
                  name="jar_o_jam"
                  onChange={formik.handleChange}
                  value={formik.values.jar_o_jam}
                  type="hidden"
                />
                <input
                  id="form_id"
                  name="form_id"
                  onChange={formik.handleChange}
                  value={formik.values.form_id}
                  type="hidden"
                />
              </div>
              <div className="col-span-2">
                <label className="text-xs text-primary block pb-2">
                  Best time to get in touch
                </label>
                <div className="grid grid-cols-2 gap-4">
                  <button
                    type="button"
                    onClick={handleToggleTime}
                    className="text-justify grid grid-cols-3 rounded-md bg-green hover:bg-opacity-75 p-1.5 text-xs mb-4 text-white shadow-toggle text-left focus:outline-none"
                  >
                    <img
                      src="/img/morning-icon.png"
                      className="hidden sm:inline-block mr-1.5"
                    />
                    <p className="col-span-3 sm:col-span-1 lg:col-span-2 m-auto">{formik.values["time"].toUpperCase()}</p>
                  </button>
                  <button
                    type="submit"
                    className="rounded-md bg-blue hover:bg-blue-700 p-4 text-xs mb-4 text-white shadow-button font-semibold focus:outline-none"
                  >
                    {formLoading ? "Sending..." : "Submit"}
                  </button>
                </div>
              </div>
              {formSubmitted && (
                <p className="text-blue-500 col-span-4">
                  Thank you! A consultant will be in touch with you soon.
                </p>
              )}
            </div>
          </form>
        </Consultation>
      </Content>
    </section>
  )
}

const Content = styled.div`
  max-width: 1080px;
  padding-left: 20px;
  padding-right: 20px;
`

const MainText = styled.p`
  max-width: 638px;
  margin-left: auto;
  margin-right: auto;
`

const ServiceList = styled.ul<{ highlighted?: boolean }>`
  list-style: none;
  padding-left: 2.25rem;
  padding-right: 0.75rem;
  & li:before {
    content: "";
    height: 1.125rem;
    width: 2.25rem;
    display: inline-block;
    vertical-align: middle;
    background-image: ${({ highlighted }) =>
      highlighted
        ? `url("/img/checkmark-white.svg")`
        : `url("/img/checkmark.svg")`};
    background-position: 0;
    background-repeat: no-repeat;
    position: relative;
    margin-top: -0.372rem;
  }
  li {
    color: ${({ highlighted }) => (highlighted ? "#fff" : "#140069")};
    padding-bottom: 0.825rem;
    text-indent: -2.25em;
  }
`

const Consultation = styled.div`
  max-width: 682px;
  padding: 0 20px 111px;
`

export default OptionsSection
