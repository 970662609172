import React, { useState } from "react"
import styled from "styled-components"

const Content = styled.div`
  max-width: 952px;
  padding: 0 24px;
`

const TextContent = styled.div`
  margin-top: 250px;
  @media screen and (min-width: 768px) {
    margin-top: 150px;
  }
`

const PrivacyContent: React.FC = () => {
  const [textVisible, setTextVisible] = useState(false)
  return (
    <section className="overflow-visible">
      <Content className="flex flex-col md:flex-row justify-between items-center mx-auto overflow-visible">
        <TextContent>
          <h2 className="text-primary text-4xl pb-6 font-bold">Privacy</h2>
          <p className="mx-6 text-primary leading-7 pb-6">
            POPI COMPLIANCE AGREEMENT
          </p>
          <p className="mx-6 text-primary leading-7 pb-6">
            We hereby confirm that our company Brima Medical Accounts (Pty) Ltd is Compliant with the
            Protection of Personal Information Act in respect to the following:
          </p>
          <ul className="mx-10 pb-9 list-decimal text-primary">
            <li className="pb-6">
              Processing of Personal Information: <br />
              As operators we undertake to ensure that all personal information
              that is being processed on behalf of our service providers is
              complete, accurate, not misleading, and up to date. We further
              confirm that all personal information will only be used for the
              single purpose of the billing services that is given to our
              clients.
            </li>
            <li className="pb-6">
              Security Safeguards: <br />
              As operators we confirm that the process used of all personal
              information is done so lawfully, by securing the confidentiality,
              integrity and the accuracy of all personal information provided by
              our service providers.
            </li>
            <li className="pb-6">
              Technical and Organisational Measures: <br />
              As operators we validate that the technical and organisational
              measures that we have in place are effective in complying with the
              internal and external risks to safeguard the data rights of all
              the service providers personal information.
            </li>
            <li className="pb-6">
              Unauthorised Processing: <br />
              As operators we confirm that we can detect the unauthorised access
              or acquisition of all personal information and will duly notify
              our service providers should such access be detected. We
              furthermore confirm that all our personnel are trained to
              distinguish between processing that is unlawful or in the
              legitimate interest of our service providers. Should a compromise
              be discovered, we confirm having suitable processes and procedures
              in place to restore the integrity of our systems.
            </li>
            <li className="pb-6">
              Service Provider Capability: <br />
              As operators we confirm adhering to all lawful conditions by being
              transparent when processing personal information. We further
              confirm that all instructions from our service providers in
              respect to the deletion and destruction of personal information
              when information is not accurate, irrelevant, excessive, out of
              date, incomplete or obtained unlawfully is adhered to by us and we
              furthermore confirm assuring our service providers that all
              instructions are immediately obeyed and followed through.
            </li>
            <li className="pb-6">
              Suspension and Termination: <br />
              As operators we advise that should a termination or suspension of
              a contract occur between us and our service providers, we remain
              responsible of the personal information that was provided to us
              and should the need arise, enable the data subject participation
              between us and our service providers.
            </li>
          </ul>
        </TextContent>
      </Content>
    </section>
  )
}

export default PrivacyContent
