import React, { AnchorHTMLAttributes, HTMLAttributes } from "react"
import styled from "styled-components"
import AnchorLink from "react-anchor-link-smooth-scroll"

const HeaderLink: React.FC<any> = ({ ...props }) => (
  <AnchorLink
    {...props}
    className="text-primary font-medium pt-2 pb-2.5 text-sm pl-6 pr-6 my-1 hover:bg-green-light hover:text-hover focus:outline-none rounded text-right md:text-left"
  />
)

type HeaderProps = {
  hideLinks: boolean
}

const Container = styled.header`
  max-width: 1248px;
  padding-left: 24px;
  padding-right: 24px;
  position: absolute;
  width: 100%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
`

const Header: React.FC<HeaderProps> = ({ hideLinks }) => {
  return (
    <Container className="pt-3.5">
      <div className="flex flex-row justify-between md:items-center">
        <a className="block" href="/">
          <div className="font-bold text-primary text-3xl">BRIMA</div>
          <div className="opacity-50 text-primary text-sm">Medical Bureau</div>
        </a>
        {!hideLinks ? (
          <div className="flex md:flex-row flex-col">
            <HeaderLink href="#services">Services</HeaderLink>
            <HeaderLink href="#why-us">Why Us</HeaderLink>
            <HeaderLink href="#about-us">About Us</HeaderLink>
            <HeaderLink href="#contact-us">Contact Us</HeaderLink>
          </div>
        ) : (
          <div className="flex md:flex-row flex-col" />
        )}
      </div>
    </Container>
  )
}

export default Header
